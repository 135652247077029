import 'regenerator-runtime/runtime';

// Prevents details from closing on tablet+ size
function preventDetailsFromClosing(){
  let details = document.querySelectorAll(".faq.hide-small > details");

  details.forEach((detail) => {
    detail.addEventListener("click", (e) => {
      e.preventDefault();
    })
  })
}
preventDetailsFromClosing();

// Adds Listeners for buttons to change active article
function addListenersForArticleChanging(){
  let offerButtons = document
    .querySelectorAll("section#offer > nav button, footer .offer span button");

  let articles = document.querySelectorAll("section#offer article");

  offerButtons.forEach((button) => {
    button.addEventListener("click", (ev) => {

      // Removes active class from button
      offerButtons.forEach((button) => {
        button.classList.remove("active");
      });

      // Removes active class from articles
      articles.forEach((article) => {
        article.classList.remove("active");
      });

      let articleToShow = ev.target.value;
      document.querySelector("[data-name=" + articleToShow + "]").classList.add("active");

      if(ev.target.dataset.name === undefined){
        document.querySelector("#offer").scrollIntoView();
      }

      let targetArticle = document.querySelector("article#" + ev.target.value);

      targetArticle.classList.add("active");
    })
  })
}
addListenersForArticleChanging();

const contactForm = document.querySelector("form");
contactForm.addEventListener("submit", (ev) => {
  ev.preventDefault();
  onFormSubmit();
})

async function sendForm(data){
  const response = await fetch("./sendmail", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })

  return response.json();
}

function onFormSubmit(){
  let formData = parseForm();

  if(formData !== false){
    showLoader();

    grecaptcha.ready(function() {
      grecaptcha.execute('6LcG_TgbAAAAAII7pZbXHafiBNH4ZiVlqPFUnFM6', {action: 'submit'}).then(function(token) {
        formData.token = token;

        sendForm(formData)
          .then(data => {
            parseResponse(data)
          })
          .catch(() => {
            parseResponse({status: 500});
          });

      });
    });
  }
}

function parseForm(){
  let formData = {};

  let fakeNumber = document.querySelector("#number").value;

  if(fakeNumber !== ""){
    return false;
  }

  let fname = document.querySelector("#fname").value;
  let email = document.querySelector("#email").value;
  let message = document.querySelector("#message").value;
  let rodo = document.querySelector("#rodo").checked;

  if(fname === "" || email === "" || message === "" || rodo === false){
    return false;
  }

  formData.fname = fname;
  formData.email = email;
  formData.message = message;

  return formData;
}

function showLoader(){
  document.querySelector("#form-overlay").classList.remove("hidden");
  document.querySelector("#form-loader").classList.remove("hidden");
}

function parseResponse(data){
  const formOverlayBackground = document.querySelector("#form-overlay");
  formOverlayBackground.classList.remove("hidden");

  const loader = document.querySelector("#form-loader");
  const formErrorInfo = document.querySelector("#form-error-info");
  const formValidInfo = document.querySelector("#form-valid-info");

  switch (data.status){
    case 200: onFormSuccess(); break;
    case 500: onFormFailure(); break;
  }

  function onFormSuccess(){
    clearForm();
    loader.classList.add("hidden");
    formValidInfo.classList.remove("hidden");
  }

  function onFormFailure(){
    loader.classList.add("hidden");
    formErrorInfo.classList.remove("hidden");
  }

  function clearForm(){
    let inputs = document.querySelectorAll("form input");
    inputs.forEach(input => {
      input.value = "";
    })

    document.querySelector("#message").value = "";
    document.querySelector("#rodo").checked = false;
  }
}

